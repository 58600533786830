import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";

import { Route, Switch } from "react-router-dom";
import { getCurrentUser } from "../../services/loginService";

import Menu from "./menu/Menu";
import Home from "./pages/home/Home";
import Login from "../login/Login";
import Invoices from "./pages/invoices/Invoices";
import EditSub from "./pages/sub/EditSub";
import CancelSub from "./pages/sub/CancelSub";
import CancelSubFinal from "./pages/sub/CancelSubFinal";
import CancelSubThanks from "./pages/sub/CancelSubThanks";

import Dialog from "../common/Dialog";

import { getCustomer, putCustomer } from "../../services/customerService";
import { activateSub, deactivateSub } from "../../services/subService";
import { putOrder } from "../../services/orderService";
import { getProductDisplayName } from "../../util/product";
import { withTranslation } from "react-i18next";

class Portal extends Component {
  state = {
    dialog: {},
    customer: {
      name: "",
      id: "",
      street: "",
      postcode: "",
      postarea: "",
      phone: "",
      email: "",
      subs: [],
    },
    user: {},
  };

  async componentDidMount() {
    const user = getCurrentUser();
    this.setState({ user });
    if (user) {
      try {
        const customer = await getCustomer();
        this.setState({ customer });
      } catch (ex) {
        toast.error(this.props.t("unknownFailure"));
      }
    }
  }

  confirmActivateSub = async () => {
    const user = this.state.user;
    let sub = this.state.dialog.id;

    if (user) {
      try {
        sub = await activateSub({ subid: sub._id });

        const index = this.state.customer.subs.findIndex((x) => x._id === sub._id);
        if (index >= 0) this.state.customer.subs[index] = sub;

        this.setState({ customer: this.state.customer });

        let productName = getProductDisplayName(sub.product.name);

        toast.info(this.props.t("subscriptionReactivated", { product: productName }));
        this.closeModal();
      } catch (ex) {
        toast.error(this.props.t("subscriptionReactivationFailed"));
      }
    }
  };

  handleActivateSub = async (e, sub) => {
    e.preventDefault();
    const user = this.state.user;
    if (user) {
      let { dialog } = this.state;
      dialog.open = true;
      dialog.id = sub;
      dialog.title = this.props.t("reactivate.title");
      dialog.text = this.props.t("reactivate.text");
      dialog.yes = this.props.t("reactivate.yes");
      dialog.no = this.props.t("reactivate.no");
      dialog.yesAction = this.confirmActivateSub;
      this.setState({ dialog });
    }
  };

  handleUpdatePerson = async () => {
    const user = this.state.user;
    if (user) {
      try {
        await putCustomer(this.state.customer);
      } catch (ex) {
        toast.info(this.props.t("saveFailed"));
      }
    }
  };

  handleCancel = async () => {
    try {
      const customer = await getCustomer();
      this.setState({ customer });
    } catch (ex) {
      toast.info(this.props.t("unknownFailure"));
    }
  };

  handleInputChange = (field, value) => {
    let customer = this.state.customer;
    customer[field] = value;
    this.setState({ customer });
  };

  handleSaveNewShipmentDate = async (order) => {
    const user = this.state.user;
    if (user) {
      try {
        await putOrder(order);
      } catch (ex) {
        toast.info(this.props.t("saveFailed"));
      }
    }
  };

  handleChangeShipmentDate = (subId, lastOrder) => {
    this.setState({ customer: this.state.customer });
  };

  handleCancelUnsub = async (subId, reasonId, freeReason, product) => {
    await deactivateSub({ subId, reasonId, freeReason, product });
    const customer = await getCustomer();
    this.setState({ customer });
  };

  closeModal = () => {
    this.setState({ dialog: { open: false } });
  };

  render() {
    return (
      <>
        {this.state.user ? (
          <React.Fragment>
            <Dialog
              open={this.state.dialog.open}
              doNo={this.closeModal}
              doYes={this.state.dialog.yesAction}
              title={this.state.dialog.title}
              text={this.state.dialog.text}
              yes={this.state.dialog.yes}
              no={this.state.dialog.no}
            />
            <Menu location={this.props.location} user={this.state.user} />
            {this.state.customer ? (
              <Switch>
                <Route path="/portal/endre/:id/avslutt/:reason/avsluttet" render={(props) => <CancelSubThanks customer={this.state.customer} {...props} />} />
                <Route
                  path="/portal/endre/:id/avslutt/:reason"
                  render={(props) => (
                    <CancelSubFinal
                      customer={this.state.customer}
                      handleCancelUnsub={this.handleCancelUnsub}
                      handleCancelUnsubFree={this.handleCancelUnsubFree}
                      handleCancel={this.handleCancel}
                      {...props}
                    />
                  )}
                />
                <Route path="/portal/endre/:id/avslutt" render={(props) => <CancelSub customer={this.state.customer} handleCancel={this.handleCancel} {...props} />} />
                <Route
                  path="/portal/endre/:id"
                  render={(props) => (
                    <EditSub
                      customer={this.state.customer}
                      handleSaveNewShipmentDate={this.handleSaveNewShipmentDate}
                      handleChangeShipmentDate={this.handleChangeShipmentDate}
                      handleCancel={this.handleCancel}
                      {...props}
                    />
                  )}
                />
                <Route path="/portal/faktura/:id" render={(props) => <Invoices customer={this.state.customer} {...props} />} />
                <Route
                  path="/"
                  render={(props) => (
                    <Home
                      user={this.state.user}
                      customer={this.state.customer}
                      handleActivateSub={this.handleActivateSub}
                      handleUpdatePerson={this.handleUpdatePerson}
                      handleCancel={this.handleCancel}
                      handleInputChange={this.handleInputChange}
                      {...props}
                    />
                  )}
                />
              </Switch>
            ) : (
              <Row style={{ textAlign: "center" }}>
                <Col className="mb-3 mt-3 col-12">
                  <h4>Laster side</h4>
                </Col>
              </Row>
            )}
          </React.Fragment>
        ) : (
          <Login />
        )}
      </>
    );
  }
}

export default withTranslation()(Portal);
