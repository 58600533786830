import React, { Component } from "react";
import { Row, Col, Form, Alert } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import "./cancelsubfinal.scss";
import { getReason } from "../../../../services/subService";
import { getProductDisplayName } from "../../../../util/product";
import { withTranslation } from "react-i18next";

const freeReasons = [
  { id: 1001, reason: "noNeed", labelKey: "dropdown.reason.1" },
  { id: 1002, reason: "expectations", labelKey: "dropdown.reason.2" },
  { id: 1003, reason: "newProduct", labelKey: "dropdown.reason.3" },
  { id: 1004, reason: "expensive", labelKey: "dropdown.reason.4" },
  { id: 9999, reason: "other", labelKey: "dropdown.reason.6" },
];

function getFreeReasonId(reason) {
  let freeReason = freeReasons.find((freeReason) => freeReason.reason === reason);
  if (freeReason) return freeReason.id;
  else return null;
}

class CancelSubFinal extends Component {
  state = {
    reason: {},
    freeReason: "",
    freeText: "",
    showFreeText: false,
    textError: "",
  };

  async componentDidMount() {
    const index = this.props.customer.subs.findIndex((x) => x._id === this.props.match.params.id);
    if (index >= 0) {
      const sub = this.props.customer.subs[index];
      let tempProd = getProductDisplayName(sub.product.name);
      const reason = await getReason(tempProd, this.props.match.params.reason);
      this.setState({ reason });
    }
  }

  onSubCancel = async (e) => {
    e.preventDefault();
    const { reason, freeReason, freeText } = this.state;
    const subId = this.props.match.params.id;

    try {
      let freeReasonId = getFreeReasonId(freeReason);
      await this.props.handleCancelUnsub(subId, freeReasonId, freeText, "Portal");
      this.props.history.push(`/portal/endre/${subId}/avslutt/${reason?.id}/avsluttet`);
    } catch (error) {
      console.error("Error cancelling subscription:", error.response?.data || error.message);
    }
  };

  onCancel = () => {
    this.props.handleCancel();
    this.props.history.push("/portal");
  };

  onDropdownSelect = (selectedReason) => {
    this.setState({
      freeReason: selectedReason,
      showFreeText: selectedReason === "other",
      freeText: "",
      textError: "",
    });
  };

  onFreeTextChange = (e) => {
    const { value } = e.currentTarget;
    const { t } = this.props;

    this.setState({ freeText: value });
  };

  render() {
    const { reason, freeReason, showFreeText, freeText, textError } = this.state;
    const { t } = this.props;

    let selectedValue = freeReason ? freeReasons.find((o) => o.reason === freeReason) : null;

    return (
      <div className="editsub-wrapper">
        <Row style={{ textAlign: "center", marginLeft: "0px", marginRight: "0px" }}>
          <Col className="mb-3 mt-3 col-12 feedback-container">
            <p>
              {/* {reason?.text ? t(reason.text) : t("dropdown.loading")} */}

              {t("feedback.request.text")}
            </p>

            {reason?.id === 9999 && (
              <>
                <DropdownButton id="dropdown-reasons" title={selectedValue ? t(selectedValue.labelKey) : t("dropdown.title")} onSelect={this.onDropdownSelect} variant="secondary">
                  {freeReasons.map((freeReason) => (
                    <Dropdown.Item key={freeReason.reason} eventKey={freeReason.reason}>
                      {t(freeReason.labelKey)}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
                {showFreeText && (
                  <Form.Group className="mt-3">
                    <Form.Label>{t("dropdown.specify")}</Form.Label>
                    <Form.Control className="cancel-textArea" as="textarea" rows={3} value={freeText} onChange={this.onFreeTextChange} placeholder={t("textarea.placeholder")} />
                    {textError && (
                      <Alert variant="danger" className="mt-2 cancelSub-textarea-warning">
                        {textError}
                      </Alert>
                    )}
                  </Form.Group>
                )}
              </>
            )}
            <div className="action-btn-container">
              <Button variant="primary" className="keep-sub-btn" onClick={this.onCancel}>
                {t("continueWithDiscount")}
              </Button>
              <Button variant="outline-danger" className="cancel-sub-btn" onClick={this.onSubCancel} disabled={freeReason === ""}>
                {t("cancelAnyway")}
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withTranslation()(CancelSubFinal);
